import { graphql } from 'gatsby'
import { PagePropsWithData, SeoData } from '../models'
import { FC } from 'react'
import Markdown from 'markdown-to-jsx'
import IndexLayout from '../layouts'
import { BackgroundImageContainer, Filler, MainContainer, Title } from '../components/styles'
import { PageHeader } from '../components'

// SECTION Main component
const GeneralTermsPage: FC<PagePropsWithData> = ({ data }) => {
  const { graphCmsGlobalContent: globalContent, graphCmsPage: page } = data

  // ANCHOR Manage SEO Data
  const seoData: SeoData = {
    pageUri: '/terms',
    title: page?.title || '',
    description: page?.description,
    openGraphImage: page?.openGraphImage?.url,
  }

  return (
    <IndexLayout seoData={seoData} palette={page?.palette}>
      {page && (
        <PageHeader backgroundColor={page.palette.headerBackgroundColor.css} wavePath={page.wavePath}>
          <MainContainer>
            <Title level={1} color={page.palette.headerTextColor.css}>
              {page.title}
            </Title>
          </MainContainer>
        </PageHeader>
      )}

      <MainContainer>
        <Filler height="8em" />
        <Markdown>{globalContent?.generalTerms}</Markdown>
      </MainContainer>
    </IndexLayout>
  )
}
// !SECTION

// ANCHOR GraphQL query
export const query = graphql`
  query GeneralTermsPageQuery {
    graphCmsGlobalContent {
      generalTerms
    }
    graphCmsPage(slug: { eq: "terms" }) {
      ...PageProps
    }
  }
`

// ANCHOR Exports
export default GeneralTermsPage
